export const environment = {
    production: false,
    debug: true,
    api: {
        graphql: 'https://digitai-demo-api.beopen.cloud/graphql',
        rest: 'https://digitai-demo-api.beopen.cloud/',
    },
    lang: {
        base: 'es',
        fallback: 'es',
        langs: ['es', 'en'],
    },
    oAuth: {
        applicationCode: 'aurora',
        applicationSecret: '$2y$10$EOA/SKEwKRgHPw64kO4LZ.6oy5b8kl6JzW/mCROMfSq6S38/IiywG',
    },
};
