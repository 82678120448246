import { FuseNavigationItem } from '@fuse/components/navigation';

export const digitaiNavigation: FuseNavigationItem = {
    id: 'digitai',
    title: 'DigitAI Aero',
    type: 'collapsable',
    icon: 'heroicons_outline:tag',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'basic',
            icon: 'mat_outline:dashboard',
            link: '/digitai/dashboard',
        },
        {
            id: 'search',
            title: 'Search',
            type: 'basic',
            icon: 'mat_outline:search',
            link: '',
        },
        {
            id: 'update fleet',
            title: 'Update Fleet',
            type: 'basic',
            icon: 'mat_outline:flight',
            link: '',
        },
        {
            id: 'file manager',
            title: 'File Manager',
            type: 'basic',
            icon: 'mat_outline:file_copy',
            link: '/digitai/file-manager',
        },
        {
            id: 'alerts log',
            title: 'Alerts Log',
            type: 'basic',
            icon: 'mat_outline:notifications_none',
            link: '',
        },
        {
            id: 'chat',
            title: 'Chat',
            type: 'basic',
            icon: 'mat_outline:chat',
            link: '/digitai/chat',
        },

        // {
        //     id: 'modelaircrafts',
        //     title: 'Modelaircraft',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/modelaircraft',
        // },
        // {
        //     id: 'modelmotors',
        //     title: 'Modelmotor',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/modelmotor',
        // },
        // {
        //     id: 'aircrafts',
        //     title: 'Aircraft',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/aircraft',
        // },
        // {
        //     id: 'fleets',
        //     title: 'Fleet',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/fleet',
        // },
        // {
        //     id: 'motors',
        //     title: 'Motor',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/motor',
        // },
        // {
        //     id: 'issues',
        //     title: 'Issue',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/issue',
        // },
        // {
        //     id: 'boundedcontexts',
        //     title: 'Boundedcontext',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/boundedcontext',
        // },
        // {
        //     id: 'aions',
        //     title: 'Aion',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/aion',
        // },
        // {
        //     id: 'issuecomments',
        //     title: 'Issuecomment',
        //     type: 'basic',
        //     icon: 'mat_outline:manage_accounts',
        //     link: '/digitai/issuecomment',
        // },
    ],
};
